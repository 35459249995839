import React from 'react';
import DocumentationSection from '../../../containers/SaasModern/Documentation';
import Image from 'reusecore/src/elements/Image';
import Screenshot from '../../../../../common/src/assets/image/about_1.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        The About window shows the version of PrintableCal and the license
        information (if purchased). Buttons are also provided to check for
        updates and to upgrade for more features. The upgrade button won't be
        shown in the Ultimate version since all features are unlocked in that
        version.
      </p>
      <p>
        <Image alt="" src={Screenshot} style={{ width: 891 }} />
      </p>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/About"
      commentsId="commentsplus_post_155_489"
      title="About PrintableCal"
      description="The About window shows the version of PrintableCal and the activation key used to register the license."
      keywords="printable calendar, calendar template, calendar add-in, Excel calendar, Word calendar, calendar data source, 2020 calendar"
      content={content}
    />
  );
};

export default Documentation;
